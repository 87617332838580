import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  ParallaxBanner,
  Parallax,
  ParallaxBannerLayer,
} from "react-scroll-parallax";
import { NavigationBar } from "../components.js";
import homeBackground from "../../assets/images/home.JPG";
import homeText from "../../assets/images/home_text.png";

import "../../styles/home/Banner.css"; // Import CSS file for styling

const Banner = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height
  const [indicatorRef, inView] = useInView();
  const [color, setColor] = useState(false);

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = -navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  // Set color to true when the indicator is in view
  useEffect(() => {
    setColor(inView);
  }, [inView]);

  return (
    <>
      <NavigationBar color={color} />

      <ParallaxBanner
        id="One-Date"
        layers={[{ image: homeBackground, speed: -30 }]}
        className="banner"
      >
        <ParallaxBannerLayer className="banner-text" speed={30}>
          <img
            src={homeText}
            width="100%"
            alt="What activities are you in the mood for?"
          />
        </ParallaxBannerLayer>
      </ParallaxBanner>

      <Parallax>
        <div
          style={{
            marginTop: navHeight,
            backgroundColor: "purple",
          }}
          ref={indicatorRef}
        />
      </Parallax>
    </>
  );
};

export default Banner;
