import { Link } from "react-router-dom";
import "../styles/Footer.css"; // Import CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-columns">
        <div className="footer-column">
          <h3>Legal</h3>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/intellectual-property">Intellectual Property</Link>
            </li>
          </ul>
          <h3>Partner</h3>
          <ul>
            <li>
              <Link to="/partner/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/partner/terms-and-conditions">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Socials</h3>
          <ul>
            <li>
              <a
                href="https://onedate.app/instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/tiktok"
                target="_blank"
                rel="noopener noreferrer"
              >
                TikTok
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/youtube"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/x"
                target="_blank"
                rel="noopener noreferrer"
              >
                X
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/lemon8"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lemon8
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/threads"
                target="_blank"
                rel="noopener noreferrer"
              >
                Threads
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/bluesky"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bluesky
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/xiaohongshu"
                target="_blank"
                rel="noopener noreferrer"
              >
                小红书
              </a>
            </li>
            <li>
              <a
                href="https://onedate.app/linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>What's More</h3>
          <ul>
            <li>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-line">© 2025 One Date • All Rights Reserved</div>
    </footer>
  );
};

export default Footer;
