import styled from "styled-components";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Content = styled.span`
  display: inline-flex;
  align-items: start;
  justify-content: center;
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 6px;
  width: 100%;
  color: inherit;
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 2px solid transparent;
  user-select: inherit;
  flex-direction: column;

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  display: block;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #000000;
  font-weight: 600;
  position: relative;
  padding: 8px;
  border-radius: 6px;
  border: none;
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: -2px -6px 15px #fff, 2px 6px 15px rgba(0, 0, 0, 0.15);
  user-select: none;
  margin: 24px;
  width: calc(100% - 48px);

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 2;
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &:before {
    background-color: transparent;
  }

  &:hover {
    cursor: pointer;

    &:before {
      background-color: ${(p) => "rgba(0, 0, 0, 0.02)"};
    }
  }

  &:focus {
    outline: none;
  }

  &:focus > ${Content} {
    border-color: rgba(50, 100, 200, 0.5);
  }

  &:active {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.02);
    &:after {
      box-shadow: inset -2px -2px 10px #fff,
        inset 2px 2px 10px rgba(0, 0, 0, 0.15);
    }
    &:before {
      background-color: transparent;
    }

    span {
      transform: translateY(-1px);
    }
  }
`;

const variant = {
  visible: {
    opacity: 1,
    scale: [0, 1],
    transition: { duration: 0.5 },
  },
};

const NeumorphismFlatContactButton = (props) => {
  const control = useAnimation();
  const [whyRef, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div
      ref={whyRef}
      variants={variant}
      initial="hidden"
      animate={control}
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <StyledButton {...props}>
        <Content tabIndex="-1">
          <h1 style={{ fontWeight: "bolder", textAlign: "start" }}>
            {props.title}
          </h1>
        </Content>
      </StyledButton>
    </motion.div>
  );
};

export default NeumorphismFlatContactButton;
