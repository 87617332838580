import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const FAQs = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date FAQs</h1>
        <h2>General</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Is One Date free to use?
            </span>
            <br />
            We offer both free and premium features.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How do I get started with One Date?
            </span>
            <br />
            Download the app, create a profile, and select your preferred date
            ideas!
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Is One Date safe to use?
            </span>
            <br />
            Your safety is our priority. You can block users who exhibit
            inappropriate behavior.
          </li>
        </ul>
        <h2>Matching</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How is One Date different from other dating apps?
            </span>
            <br />
            One Date focuses on shared activities for the first date, ensuring
            compatibility beyond just profiles.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Can I still chat with matches before the date?
            </span>
            <br />
            Absolutely! Chatting is encouraged to solidify details and get to
            know each other better.
          </li>
        </ul>
        <h2>Date Ideas</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What kind of date ideas are available?
            </span>
            <br />
            One Date offers a variety of options, from dining and entertainment
            to social experiences.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Can I suggest my own date ideas?
            </span>
            <br />
            Yes! We love fresh ideas. Share them through our recommendations
            email at{" "}
            <a
              style={{ color: "inherit" }}
              href="mailto:recommendations@onedate.app"
            >
              recommendations@onedate.app
            </a>
            .
          </li>
        </ul>
        <h2>Local Businesses</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How does One Date support local businesses?
            </span>
            <br />
            Businesses can now upload their own deals directly on One Date,
            reaching singles and couples looking for great date experiences.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What types of businesses are included?
            </span>
            <br />
            Any business offering date-friendly experiences can join, including
            restaurants, bars, activity centers, and more.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What are the benefits of listing deals on One Date?
            </span>
            <br />
            Gain exposure to an engaged audience, increase bookings, and attract
            new customers looking for unique date ideas.
          </li>
        </ul>
        <h2>Wingman Referral Program</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What is the Wingman Program?
            </span>
            <br />
            The Wingman Program lets you refer friends and earn rewards
            together! When you wingman someone, both of you receive One Date
            credits and a profile boost.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How do I refer friends?
            </span>
            <br />
            You can find your referral code by tapping the QR code icon on your
            profile page. Share it with friends, and when they sign up using
            your code, you both get rewarded!
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What rewards do I get for referring friends?
            </span>
            <br />
            Both you and your referred friend will earn One Date credits, which
            can be used for premium features. Plus, your profile gets a boost,
            increasing your chances of making great connections!
          </li>
        </ul>

        <h2>Account Deletion</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How do I delete my One Date account?
            </span>
            <br />
            We're sad to see you go! To delete your account, go to your profile
            page, tap on the One Date logo, and select "Delete Account." Please
            note that this action is permanent and cannot be undone.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What happens when I delete my account?
            </span>
            <br />
            Deleting your account will remove your profile, matches,
            conversations, and any stored data. If you decide to return, you'll
            need to create a new account.
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default FAQs;
