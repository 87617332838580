import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  NavigationBar,
  NeumorphismFlatContactButton,
} from "../components/components";
import "../styles/contactus/ContactUs.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const ContactUs = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  const handleEmailClick = (emailAddress, subject) => {
    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;
  };

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div
        className="contact-us"
        style={{
          paddingTop: navHeight,
        }}
      >
        <h1 style={{ margin: "0 32px" }}>Need Help? We've Got You Covered!</h1>
        <NeumorphismFlatContactButton
          onClick={() =>
            handleEmailClick(
              "privacy@onedate.app",
              "One Date Privacy Policy Inquiry"
            )
          }
          title="For inquiries regarding our Privacy Policy, please contact us here."
        />
        <NeumorphismFlatContactButton
          onClick={() =>
            handleEmailClick(
              "terms@onedate.app",
              "One Date Terms and Conditions Inquiry"
            )
          }
          title="For inquiries regarding our Terms and Conditions, please contact us here."
        />
        <NeumorphismFlatContactButton
          onClick={() =>
            handleEmailClick(
              "notice@onedate.app",
              "One Date Intellectual Property Inquiry"
            )
          }
          title="For inquiries regarding our Intellectual Property, please contact us here."
        />
        <NeumorphismFlatContactButton
          onClick={() => handleEmailClick("contact@onedate.app", "")}
          title="For all other questions, suggestions, or feedback, including app improvements or concerns, feel free to reach out to us here!"
        />
      </div>
    </motion.div>
  );
};

export default ContactUs;
